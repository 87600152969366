<template>
  <div class="popup_wrap" style="width:1200px; min-height:200px; max-height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <div v-if="rfResultList.length === 0 && oogResultList.length === 0 && dgResultList.length === 0">
        <h1 class="page_title">{{ $t('btn.CMBTK045') }}</h1>

        <div class="content_box">
          <p class="p_nodata">{{ $t('msg.CSBL200.209') }}</p>
        </div>
      </div>
      <div v-if="rfResultList.length > 0">
        <h1 class="page_title">{{ $t('msg.ONEX050P081.001') }}</h1> <!-- B/L RF(RH) Entry -->

        <div class="content_box">
          <table class="tbl_col">
            <colgroup>
              <col style="width:10%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                <th scope="col">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th scope="col">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th scope="col">{{ $t('msg.ONEX010P090.041') }}</th> <!-- CGO -->
                <th scope="col">{{ $t('msg.ONEX010P090.056') }}</th> <!-- Plug -->
                <th scope="col">{{ $t('msg.ONEX010P090.057') }}</th> <!-- Temp -->
                <th scope="col">{{ $t('msg.ONEX010P090.058') }}</th> <!-- C/F -->
              </tr>
            </thead>
            <tbody v-if="rfResultList.length > 0">
              <tr v-for="(vo, idx) in rfResultList" :key="'cntrRf_' + idx">
                <td>{{ idx + 1 }}</td>
                <td>{{ vo.cntrSzCdNm }}</td>
                <td>{{ vo.cntrTypCd }}</td>
                <td>{{ vo.cgoTypCdNm }}</td>
                <td>
                  <input
                    v-model="vo.rfSlecUseYn"
                    :id="'rfSlecUseYn_' + idx"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  >
                  <label :for="'rfSlecUseYn_' + idx"></label>
                </td>
                <td>{{ vo.rfSetupTmpr }}</td>
                <td>{{ vo.rfTmprUnitCd }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="dgResultList.length > 0">
        <h1 class="page_title">{{ $t('msg.ONEX050P081.002') }}</h1> <!-- B/L D/G Entry -->

        <div class="content_box">
          <table class="tbl_col">
            <colgroup>
              <col style="width:5%">
              <col style="width:5%">
              <col style="width:5%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:7%">
              <col style="width:7%">
              <col style="width:7%">
              <col style="width:16%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.349') }}</th> <!-- UN No. -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.378') }}</th> <!-- IMO Class -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.396') }}</th> <!-- F/P -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.397') }}</th> <!-- P'kgs -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.398') }}</th> <!-- 총중량 (Kg)-->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.399') }}</th> <!-- 순중량 -->
                <th scope="col" colspan="3">{{ $t('msg.CSBK100.400') }}</th><!-- Packing -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.401') }}</th><!-- 긴급연락처/담당자 -->
              </tr>
              <tr>
                <th class="td_border" scope="col">{{ $t('msg.CSBK100.402') }}</th><!-- Group -->
                <th scope="col">{{ $t('msg.CSBK100.403') }}</th><!-- Type1 -->
                <th scope="col">{{ $t('msg.CSBK100.404') }}</th><!-- Type2 -->
              </tr>
            </thead>
            <tbody v-if="dgResultList.length > 0">
              <tr v-for="(vo, idx) in dgResultList" :key="'cntrDg_' + idx">
                <td>{{ vo.cntrSeq }}</td>
                <td>{{ vo.cntrSzCdNm }}</td>
                <td>{{ vo.cntrTypCd }}</td>
                <td class="td_border">{{ vo.imdunNo }}</td>
                <td>{{ vo.imoClasCd }}</td>
                <td>{{ vo.flsTmpr }} <span v-show="$ekmtcCommon.isNotEmpty(vo.flsTmpr)">{{ vo.tmprUnitCd === 'C' ? '℃' : '℉' }}</span></td>
                <td>{{ vo.dgPkgQty }}</td>
                <td>{{ vo.grsWt }}</td>
                <td>{{ vo.netWt }}</td>
                <td>{{ vo.dgPkgGrpCdNm }}</td>
                <td>{{ vo.dgKndCdNm }}</td>
                <td>{{ vo.dgMatlCdNm }}</td>
                <td>{{ vo.dgCtfcDsc }} / {{ vo.dgPicNm }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="oogResultList.length > 0">
        <h1 class="page_title">{{ $t('msg.ONEX050P081.003') }}</h1> <!-- B/L OOG Entry -->

        <div class="content_box">
          <table class="tbl_col">
            <colgroup>
              <col style="width:10%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:18%">
              <col style="width:9%">

              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th scope="col" rowspan="2">{{ $t('msg.ONEX050P081.004') }}</th> <!-- Cargo Type -->
                <th scope="col" rowspan="2">{{ $t('msg.ONEX050P081.005') }}</th> <!-- F/E -->
                <th scope="col" colspan="5">{{ $t('msg.ONEX050P081.006') }}</th> <!-- Over(㎝) -->
              </tr>
              <tr>
                <th class="border_left">OH</th>
                <th>OF</th>
                <th>OB</th>
                <th>OWP</th>
                <th>OWS</th>
              </tr>
            </thead>
            <tbody v-if="oogResultList.length > 0">
              <tr v-for="(vo, idx) in oogResultList" :key="'cntrOog_' + idx">
                <td>{{ idx + 1 }}</td>
                <td>{{ vo.cntrSzCdNm }}</td>
                <td>{{ vo.cntrTypCd }}</td>
                <td>{{ vo.cgoTypCdNm }}</td>
                <td>{{ vo.feCatCd }}</td>
                <td>{{ vo.ovrHght }}</td>
                <td>{{ vo.ovrFwdLen }}</td>
                <td>{{ vo.ovrBwrdLen }}</td>
                <td>{{ vo.ovrPortLen }}</td>
                <td>{{ vo.ovrStbdLen }}</td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc">{{ $t('msg.ONEX050P081.007') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'BLContainerCgoPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          modifyFlag: 'I',
          blNo: '',
          bkgNo: '',
          type: 'SR'
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      rfResultList: [],
      dgResultList: [],
      oogResultList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const arrCdId = ['01031', '01038', '01186', '01233', '01234']

      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res
      })

      const blParams = {
        bkgNo: this.parentInfo.bkgNo,
        blNo: this.parentInfo.blNo,
        modifyFlag: this.parentInfo.modifyFlag,
        type: this.parentInfo.type
      }

      await Promise.all([
        trans.findOOGInfo(blParams).then(res => {
          const result = res.data

          if (res.headers.respcode === 'C0000' && result !== undefined && result.length > 0) {
            const cntrSzList = this.commonCodes['01031']
            const cgoTypCdList = this.commonCodes['01038']

            result.forEach(c => {
              const cntrSzInfo = cntrSzList.filter(vo => vo.cd === c.cntrSzCd)
              if (cntrSzInfo !== undefined && cntrSzInfo.length > 0) {
                c.cntrSzCdNm = cntrSzInfo[0].cdNm
              }

              const cgoTypInfo = cgoTypCdList.filter(vo => vo.cd === c.cgoTypCd)
              if (cgoTypInfo !== undefined && cgoTypInfo.length > 0) {
                c.cgoTypCdNm = cgoTypInfo[0].cdNm
              }
              //c.cntrTypCd = (c.cntrTypCd === 'FR') ? 'OT' : c.cntrTypCd
            })

            this.oogResultList = result
          }
        }),
        trans.findDGInfo(blParams).then(res => {
          const result = res.data

          if (res.headers.respcode === 'C0000' && result !== undefined && result.length > 0) {
            const cntrSzList = this.commonCodes['01031']
            const cgoTypCdList = this.commonCodes['01038']
            const dgPkgGrpCdList = this.commonCodes['01186']
            const dgKndCdList = this.commonCodes['01233']
            const dgMatlCdList = this.commonCodes['01234']

            result.forEach(c => {
              const cntrSzInfo = cntrSzList.filter(vo => vo.cd === c.cntrSzCd)
              if (cntrSzInfo !== undefined && cntrSzInfo.length > 0) {
                c.cntrSzCdNm = cntrSzInfo[0].cdNm
              }

              const cgoTypInfo = cgoTypCdList.filter(vo => vo.cd === c.cgoTypCd)
              if (cgoTypInfo !== undefined && cgoTypInfo.length > 0) {
                c.cgoTypCdNm = cgoTypInfo[0].cdNm
              }

              const dgPkgGrpInfo = dgPkgGrpCdList.filter(vo => vo.cd === c.dgPkgGrpCd)
              if (dgPkgGrpInfo !== undefined && dgPkgGrpInfo.length > 0) {
                c.dgPkgGrpCdNm = dgPkgGrpInfo[0].cdNm
              }

              const dgKndInfo = dgKndCdList.filter(vo => vo.cd === c.dgKndCd)
              if (dgKndInfo !== undefined && dgKndInfo.length > 0) {
                c.dgKndCdNm = dgKndInfo[0].cdNm
              }

              const dgMatlInfo = dgMatlCdList.filter(vo => vo.cd === c.dgMatlCd)
              if (dgMatlInfo !== undefined && dgMatlInfo.length > 0) {
                c.dgMatlCdNm = dgMatlInfo[0].cdNm
              }
              c.cntrTypCd = (c.cntrTypCd === 'GP') ? 'HZ' : c.cntrTypCd
            })

            this.dgResultList = result
          }
        }),
        trans.findRFInfo(blParams).then(res => {
          const result = res.data

          if (res.headers.respcode === 'C0000' && result !== undefined && result.length > 0) {
            const cntrSzList = this.commonCodes['01031']
            const cgoTypCdList = this.commonCodes['01038']

            result.forEach(c => {
              const cntrSzInfo = cntrSzList.filter(vo => vo.cd === c.cntrSzCd)
              if (cntrSzInfo !== undefined && cntrSzInfo.length > 0) {
                c.cntrSzCdNm = cntrSzInfo[0].cdNm
              }

              const cgoTypInfo = cgoTypCdList.filter(vo => vo.cd === c.cgoTypCd)
              if (cgoTypInfo !== undefined && cgoTypInfo.length > 0) {
                c.cgoTypCdNm = cgoTypInfo[0].cdNm
              }
            })

            this.rfResultList = result
          }
        })
      ])
    }
  }
}
</script>

<style>
  .td_border {border-left: 1px solid #e1e3eb !important;}
  .p_nodata {text-align: center; font-size: 16px; font-weight: 600;}
</style>
